import React, { useState } from "react"
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonMenuButton,
  IonSelect,
  IonSelectOption,
  IonButton,
} from "@ionic/react"
import { useSwipeable } from "react-swipeable"

import "./ExploreContainer.scss"

interface ExploreContainerProps {
  videos: string[]
}

const ExploreContainer: React.FC<ExploreContainerProps> = ({ videos }) => {
  // Separate state for video, chapter, and share carousels
  const [videoIndex, setVideoIndex] = useState(0)
  const [chapterIndex, setChapterIndex] = useState(0)
  const [shareIndex, setShareIndex] = useState(0)
  // Default option is "substitles" (video carousel)
  const [selectedOption, setSelectedOption] = useState("substitles")

  // Constant arrays of images (adjust paths as needed)
  const chapterImages = [
    "/assets/chap1.jpg",
    "/assets/chap2.jpg",
    "/assets/chap3.jpg",
  ]

  const shareImages = [
    "/assets/share1.jpg",
    "/assets/share2.jpg",
    "/assets/share3.jpg",
  ]

  // Swipe handler for video carousel
  const videoSwipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (videoIndex < videos.length - 1) setVideoIndex(videoIndex + 1)
    },
    onSwipedRight: () => {
      if (videoIndex > 0) setVideoIndex(videoIndex - 1)
    },
    trackMouse: true,
  })

  // Swipe handler for chapter images
  const chapterSwipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (chapterIndex < chapterImages.length - 1)
        setChapterIndex(chapterIndex + 1)
    },
    onSwipedRight: () => {
      if (chapterIndex > 0) setChapterIndex(chapterIndex - 1)
    },
    trackMouse: true,
  })

  // Swipe handler for share images
  const shareSwipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (shareIndex < shareImages.length - 1)
        setShareIndex(shareIndex + 1)
    },
    onSwipedRight: () => {
      if (shareIndex > 0) setShareIndex(shareIndex - 1)
    },
    trackMouse: true,
  })

  // Button navigation functions for all carousels
  const goLeft = () => {
    if (selectedOption === "substitles" && videoIndex > 0) {
      setVideoIndex(videoIndex - 1)
    } else if (selectedOption === "chapters" && chapterIndex > 0) {
      setChapterIndex(chapterIndex - 1)
    } else if (selectedOption === "sharing" && shareIndex > 0) {
      setShareIndex(shareIndex - 1)
    }
  }

  const goRight = () => {
    if (selectedOption === "substitles" && videoIndex < videos.length - 1) {
      setVideoIndex(videoIndex + 1)
    } else if (
      selectedOption === "chapters" &&
      chapterIndex < chapterImages.length - 1
    ) {
      setChapterIndex(chapterIndex + 1)
    } else if (
      selectedOption === "sharing" &&
      shareIndex < shareImages.length - 1
    ) {
      setShareIndex(shareIndex + 1)
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {/* Left-side controls */}
          <IonButtons slot="start">
            <IonMenuButton color="primary" />
          </IonButtons>
          {/* Dropdown control */}
          <IonSelect
            slot="start"
            value={selectedOption}
            onIonChange={(e) => setSelectedOption(e.detail.value)}
            interface="popover"
            style={{ marginLeft: "10px" }}
          >
            <IonSelectOption value="substitles">
              Using Substitles
            </IonSelectOption>
            <IonSelectOption value="chapters">
              Create Chapters
            </IonSelectOption>
            <IonSelectOption value="sharing">
              Sharing custom subs and chapters
            </IonSelectOption>
          </IonSelect>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className="compact-list-padding">
        {selectedOption === "substitles" ? (
          <div
            className="explainer-container"
            {...videoSwipeHandlers}
            style={{ position: "relative" }}
          >
            <div className="video-wrapper">
              <video
                autoPlay
                loop
                muted
                playsInline
                src={videos[videoIndex]}
              />
            </div>
            {/* Left button */}
            <IonButton
              fill="clear"
              style={{
                position: "absolute",
                top: "50%",
                left: "10px",
                transform: "translateY(-50%)",
                backgroundColor: "rgba(0,0,0,0.3)",
              }}
              onClick={goLeft}
            >
              {"<"}
            </IonButton>
            {/* Right button */}
            <IonButton
              fill="clear"
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                backgroundColor: "rgba(0,0,0,0.3)",
              }}
              onClick={goRight}
            >
              {">"}
            </IonButton>
            <div className="dot-indicators">
              {videos.map((_, index) => (
                <div
                  key={index}
                  className={`dot ${videoIndex === index ? "active" : ""}`}
                ></div>
              ))}
            </div>
          </div>
        ) : selectedOption === "chapters" ? (
          <div
            className="chapter-container"
            {...chapterSwipeHandlers}
            style={{ position: "relative" }}
          >
            <div className="image-wrapper">
              <img
                src={chapterImages[chapterIndex]}
                alt="Chapter slide"
                style={{
                  width: "100vw",
                  height: "100vh",
                  objectFit: "contain",
                }}
              />
            </div>
            {/* Left button */}
            <IonButton
              fill="clear"
              style={{
                position: "absolute",
                top: "50%",
                left: "10px",
                transform: "translateY(-50%)",
                backgroundColor: "rgba(0,0,0,0.3)",
              }}
              onClick={goLeft}
            >
              {"<"}
            </IonButton>
            {/* Right button */}
            <IonButton
              fill="clear"
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                backgroundColor: "rgba(0,0,0,0.3)",
              }}
              onClick={goRight}
            >
              {">"}
            </IonButton>
            <div className="dot-indicators">
              {chapterImages.map((_, index) => (
                <div
                  key={index}
                  className={`dot ${chapterIndex === index ? "active" : ""}`}
                ></div>
              ))}
            </div>
          </div>
        ) : selectedOption === "sharing" ? (
          <div
            className="sharing-container"
            {...shareSwipeHandlers}
            style={{ position: "relative" }}
          >
            <div className="image-wrapper">
              <img
                src={shareImages[shareIndex]}
                alt="Sharing slide"
                style={{
                  width: "100vw",
                  height: "100vh",
                  objectFit: "contain",
                }}
              />
            </div>
            {/* Left button */}
            <IonButton
              fill="clear"
              style={{
                position: "absolute",
                top: "50%",
                left: "10px",
                transform: "translateY(-50%)",
                backgroundColor: "rgba(0,0,0,0.3)",
              }}
              onClick={goLeft}
            >
              {"<"}
            </IonButton>
            {/* Right button */}
            <IonButton
              fill="clear"
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                backgroundColor: "rgba(0,0,0,0.3)",
              }}
              onClick={goRight}
            >
              {">"}
            </IonButton>
            <div className="dot-indicators">
              {shareImages.map((_, index) => (
                <div
                  key={index}
                  className={`dot ${shareIndex === index ? "active" : ""}`}
                ></div>
              ))}
            </div>
          </div>
        ) : null}
      </IonContent>
    </IonPage>
  )
}

export default ExploreContainer
