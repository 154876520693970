import React, { useState , useEffect, useRef} from 'react';
import { useHistory, useLocation } from "react-router-dom"
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonFooter,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonAccordion,
  IonAccordionGroup,
  IonItem,
  IonLabel,
  IonIcon,
  IonInput,
  IonText,
} from '@ionic/react';
import {checkmarkSharp, rocketSharp, planet, warning, thunderstormSharp } from 'ionicons/icons';



const LandingPage2: React.FC = () => {
  const formRef = useRef<HTMLDivElement>(null); // Create a ref for the form
  const location = useLocation(); 
  const history = useHistory();
  // Mock pricing data (replacing the config)
  const pricingPlans = [
    {
      name: 'Basic',
      description: 'Free (invite code required)',
      price: '0',
      features: ['Local Storage Only', '3 transcription requests daily', 'Transcriptions limited to videos under 1 hour'],
      isFeatured: true,
    },
    {
      name: 'Plus',
      description: 'Yearly',
      price: 'xx',
      features: ['Online Backups', '100 transcription requests daily', 'Transcriptions limited to videos under 2 hours', 'AI Assistants'],
    },
    {
      name: 'Plus Family',
      description: '2-Year (5 users)',
      price: 'xx',
      features: ['Online Backups', '100 transcription requests daily', 'Transcriptions limited to videos under 2 hours','AI Assistants'],
    },
  ];

  // Mock FAQ data
  const faqList = [
    {
      question: "Subtitles for almost any Youtube video?",
      answer: "Many videos already have subtitles, but for the ones that do not, we use the latest AI technologies to generate the subtitles. Do note that subtitles often do have errors, even the ones provided by the creator, we allow you to edit them. "
    },
    {
      question: "Is this good for kids to use?",
      answer: "Yes, it was designed for a 9yr old to use, the only caveat is that it has access to full Youtube content so parental supervision is recommended."
    },
    {
      question: "Seems useful for general learning too?",
      answer: "It is built for language learning but the process of learning in general is similar across the board, it is also great for learning anything video content! We'll be showcasing more examples on our social channels."
    },
    {
      question: "Is there a mobile version?",
      answer: "Currently it is a responsive web app, which works great on mobile devices, just browse to the webpage and add a shortcut to your homescreen and launch the app from there. Dedicated mobile apps will release in later versions."
    },
    {
      question: "Bulk license discounts for institutions and corporates?",
      answer: "Yes, please reach out to us, we'd be happy to draft a custom quote."
    },
    {
      question: "I'm excited, how can I get involved or provide feedback?",
      answer: "Reach out to us by using the form below or at X.com (@SpaceSubsApp), we are engaging the community for feedback and to provide the best app possible for learning!"
    },
  ];

  // Mock features
  const features = [
    { title: "Generate Subtitles", description: "You choose the content you want to work with. We can generate subtitles for most Youtube videos (region restrictions apply) under 1hr in length.", image:"zGenerateSubs.mp4" },
    { title: "Bookmarking", description: "Watch only the parts of the video you are interested in.", image:"zBookmark.mp4" },
    { title: "Dual Subtitle Translate", description: "Bi-directional word translations that can be added to flashcards.", image:"zDualSubs.mp4" },
    { title: "Flashcards", description: "Generate flashcards with a few clicks. Two sets, one of them requires active recall.",image:"zFlashcards.mp4" },
    { title: "Vocabulary Builder", description: "Vocabulary Word Of the Day up to B1 CEFR level, to keep you engaged and leveling up.", image:"zWordDay.mp4"},
    { title: "🔥Custom Subtitles", description: "Create subtitle packs to test your transcriptions, this really forces you to learn the material.", image:"zCustomSubs.mp4"}
  ];
  const [selectedFeature, setSelectedFeature] = useState<string>("feature-0");

  const [howHeard, setHowHeard] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [submittedData, setSubmittedData] = useState<{ email: string; howHeard: string } | null>(null);

  // Scroll to the specific section if the URL hash matches
  useEffect(() => {
    const hash = location.hash; // Check the current hash
    if (hash === '#form' && formRef.current) {
      setTimeout(() => {
        formRef.current?.scrollIntoView({ behavior: 'smooth' }); // Add a slight delay
      }, 300); // Adjust the delay as needed
    }
  }, [location]);

  // Email validation function
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    return emailRegex.test(email);
  };

    // Handle email input change
    const handleEmailChange = (value: string) => {
      setEmail(value);
      setIsEmailValid(validateEmail(value));
    };

    const handleHowHeardChange = (value: string) => {
      if (value.length <= 256) {
        setHowHeard(value);
      } else {
        setHowHeard(value.slice(0, 256)); // Ensure no more than 256 characters
      }
    };

  const handleSubmitEmail = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      // Map Ionic form fields to Google Form entry IDs
      formData.append('entry.1214775609', howHeard); // "How did you hear about us?"
      formData.append('entry.1677594976', email); // "Email"

      await fetch(
        'https://docs.google.com/forms/d/e/1FAIpQLSfDWukTdHztNW2UPiWL2B2gyROK0Mw9Lg8fLpbOo3SdXZCx8A/formResponse',
        {
          method: 'POST',
          body: formData,
          mode: 'no-cors', // Required for Google Forms submissions
        }
      );

      setStatus('Submission successful! We will get back to you soon!');
      setSubmittedData({ email, howHeard }); // Store submitted data
      setHowHeard('');
      setEmail('');
    } catch (error) {
      console.error('Error submitting form:', error);
      setStatus('Submission failed. Please try again.');
    }
  };


  return (
    <IonPage>
      {/* Header */}
      <IonHeader>
        <IonToolbar>
        <IonTitle>
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <span>Space Subs</span>
        <span 
          style={{
            color: 'var(--ion-color-primary)',
            opacity: 0.95,
            fontSize: '0.9em',
            marginLeft: '4px',
          }}
        >
          (alpha preview)
        </span>
      </div>
    </IonTitle>
    {/* Add Login Link */}
 
        <IonButton slot="end" onClick={() => history.push("/login")}  color="primary" size="small" fill="outline">
          Login
        </IonButton>


        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {/* Hero Section */}
        <section style={{ padding: '2rem 1rem'}}>




      <IonGrid>
        <IonRow className="ion-align-items-center ion-justify-content-center">
          {/* Left Column (Text) */}
          <IonCol
            size="12"
            sizeMd="5"
            className="ion-text-start ion-text-center ion-text-md-start"
          >
            <h1 style={{ color:'rgb(220,220,220)', fontFamily: 'Poppins, sans-serif', fontWeight: 'bold', fontSize: '3rem', marginBottom: '1rem' }}>
              Language learning with content you like. <IonIcon color="danger" style={{stroke: 'red', strokeWidth: '2px',fontSize: '.9em'}} icon={rocketSharp}></IonIcon>
            </h1>
            <p style={{ fontSize: '1.2rem', opacity: 0.8, marginBottom: '2rem' }}>
              Helping you easily practice with the most proven learning techniques, like spaced repetition and active recall. Come space-out with Space Subs!
            </p>
            {/* <IonButton onClick={() => history.push("/signup")} color="warning" expand="block" style={{ maxWidth: '200px', marginBottom: '2rem' }}>
              Get Space Subs
            </IonButton> */}
            <IonButton onClick={() => formRef.current?.scrollIntoView({ behavior: 'smooth' })} color="primary" expand="block" style={{ maxWidth: '200px', marginBottom: '2rem' }}>
              Get Space Subs
            </IonButton>

            {/* Example of avatars and rating if needed */}
            {/* <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <div style={{ display: 'flex' }}>
                <img src="avatar1.jpg" alt="User 1" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                <img src="avatar2.jpg" alt="User 2" style={{ width: '40px', height: '40px', borderRadius: '50%', marginLeft: '-10px' }} />
                <img src="avatar3.jpg" alt="User 3" style={{ width: '40px', height: '40px', borderRadius: '50%', marginLeft: '-10px' }} />
                <img src="avatar4.jpg" alt="User 4" style={{ width: '40px', height: '40px', borderRadius: '50%', marginLeft: '-10px' }} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.9rem' }}>
                <span style={{ color: '#FFD700', marginRight: '0.5rem' }}>★★★★★</span>
                <span>32 makers space out with Space Subs</span>
              </div>
            </div> */}
          </IonCol>

          {/* Right Column (Iframe Video) */}
          <IonCol
            size="12"
            sizeMd="7"
            className="ion-text-center ion-margin-top ion-margin-md-top-0"
          >
            <div style={{ maxWidth: '800px', margin: '0 auto', border: '2px solid rgb(62, 54, 79)' }}>
              <iframe
                width="100%"
                height="415"
                src="https://www.youtube.com/embed/PgS3V6NVfRk"
                title="YouTube video player"
                frameBorder="0"
                allowFullScreen
                style={{ borderRadius: '8px', overflow: 'hidden' }}
              ></iframe>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </section>



        {/* Problem Section */}
        <section style={{ background: 'rgb(39,45,62', padding: '2rem',position: 'relative', overflow: 'hidden' }}>
        <IonIcon
    icon={thunderstormSharp}
    color='dark'
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '500px', // Adjust size as needed
      //color: 'rgb(220,220,220)', // Match the text color
      opacity: '0.1',
      zIndex: '0', // Ensure it stays behind the content
    }}
  />
          <IonGrid>
            <IonRow className="ion-justify-content-center ion-text-center">
              <IonCol size="12" sizeMd="8">
                {/* <h1 style={{color:'rgb(254,216,111)', fontFamily: 'Inter, sans-serif',fontWeight: '800', fontSize: '3rem',}}>Most learners quit before reaching fluency in a second language.</h1> */}
                <h1 style={{color:'rgb(220,220,220)', fontFamily: 'Inter, sans-serif',fontWeight: '800', fontSize: '3rem',}}>Most learners quit before reaching fluency in a second language.</h1>
                <p style={{ fontSize: '1.6rem', opacity: 0.8, marginBottom: '2rem' }}>De-prioritize boring and irrelevant learning content!</p>
                <IonGrid>
                  <IonRow className="ion-justify-content-center">
                    <IonCol className="ion-text-center">
                    <div>
    <img src="assets/zneutral.png" alt="Neutral face" style={{ width: '150px', height: '150px' }} />
  </div>
  <p>Complex grammar rules</p>
</IonCol>
<IonCol className="ion-text-center">
  <div>
    <img src="assets/zsad.png" alt="Sweating face" style={{ width: '150px', height: '150px' }} />
  </div>
  <p>Uninteresting vocabulary</p>
</IonCol>
<IonCol className="ion-text-center">
  <div>
    <img src="assets/zsweating.png" alt="Sad face" style={{ width: '150px', height: '150px' }} />
  </div>
  <p>Quit learning</p>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
          </IonGrid>
        </section>

        {/* Features Accordion Section */}
        <section className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeMd="6">
                {/* <h2 style={{ fontFamily: 'Inter, sans-serif', fontWeight: '600', fontSize: '1.8rem', color:'rgb(220,220,220)'}} >All you need to start learning. We provide the tools for you in one simple app.</h2> */}
                <h2 style={{ fontFamily: 'Inter, sans-serif', fontWeight: '600', fontSize: '1.8rem', color:'rgb(220,220,220)'}} >Learning is already challenging—why struggle with complicated tools? Space Subs simplifies practice with a single, user-friendly app.</h2>
                <IonAccordionGroup style={{ background: 'rgb(30,32,44)' }} value={selectedFeature} onIonChange={e => {
                  const val = e.detail.value;
                  if (val) setSelectedFeature(val.toString());
                }}>
                  {features.map((feat, index) => (
                    <IonAccordion style={{ background: 'rgb(30,32,44)' }} value={`feature-${index}`} key={index}>
                      <IonItem  style={{ background: 'rgb(30,32,44)' }}  slot="header">
                        <IonLabel style={{fontWeight:'600', opacity:'.8'}}>{feat.title}</IonLabel>
                      </IonItem>
                      <div style={{background: 'rgb(30,32,44)' }} className="ion-padding" slot="content">
                        {feat.description}
                      </div>
                    </IonAccordion>
                  ))}
                </IonAccordionGroup>
              </IonCol>
              <IonCol size="12" sizeMd="6">
                {/*  show a related image or video based on the selected feature */}
                {/* <img
                  // src="https://via.placeholder.com/400"
                  src={`/assets/${
                    features[
                      parseInt(selectedFeature.split('-')[1])
                    ]?.image
                  }`}
                  alt="Feature Preview"
                  style={{ width: '100%', borderRadius: '8px' }}
                /> */}
                <video
                  src={`/assets/${
                    features[
                      parseInt(selectedFeature.split('-')[1])
                    ]?.image
                  }`}
                  loop
                  autoPlay
                  muted
                  style={{ width: '100%', borderRadius: '8px' }}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </section>

        {/* Pricing Section */}
        <section style={{ background: 'rgb(39,45,62', padding: '2rem' }} id="pricing">
          <IonGrid>
            <IonRow style={{display: 'flex',flexWrap: 'wrap',}}className="ion-text-center ion-justify-content-center">
              <IonCol size="12">
                {/* <h2 style={{ fontSize: '2rem', color:'rgb(220,220,220)'}} >Save hours spent on creating flashcards! Boost your vocabulary, watch, practice, and remember.</h2> */}
                <h2 style={{ fontSize: '2rem', color:'rgb(220,220,220)'}} >Boost your vocabulary, watch, practice, and remember.</h2>
                <h3 style={{color:'rgb(254,216,111)', fontSize:'1em'}}>(During Alpha release, only Basic free plan is available)</h3>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center" style={{padding:"10px" }}>
              {pricingPlans.map((plan, i) => (
                <IonCol key={i} size="12" sizeMd="4" sizeLg="4" style={{display: 'flex',flexDirection: 'column',}}>
                  <IonCard style={{ flexDirection: 'column', display:'flex', flex: 1, background:'rgb(30, 32, 45)' , border: plan.isFeatured ? '2px solid #3880ff' : 'none' }}>
                    <IonCardHeader>
                      <IonCardTitle>{plan.name}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent style={{
                          flex: 1, // Makes the content fill available space
                          display: 'flex', // Aligns CardContent content
                          flexDirection: 'column', // Stacks content vertically
                          justifyContent: 'space-between', // Pushes button to the bottom
                        }}>
                      <p>{plan.description}</p>
                      <h3 style={{ fontSize: '2rem', fontWeight: 'bold' }}>${plan.price}</h3>
                      <ul style={{ listStyle: 'none', padding: 0 }}>
                        {plan.features.map((f, idx) => (
                          <li key={idx} style={{display: 'flex',alignItems: 'center', marginBottom: '0.5rem', }}>
                            <IonIcon
                              icon={checkmarkSharp}
                              style={{
                                color: 'green',
                                marginRight: '0.5rem',
                              }}
                            />
                            {f}</li>
                        ))}
                      </ul>
                      {/* <IonButton style={{ marginTop: 'auto' }} disabled={plan.name !== 'Basic'} onClick={() => history.push("/signup")} color="warning" expand="block">Get Now</IonButton> */}
                                            <IonButton
                                              style={{ marginTop: 'auto' }}
                                              disabled={plan.name !== 'Basic'}
                                              onClick={() => formRef.current?.scrollIntoView({ behavior: 'smooth' })}
                                              color="primary"
                                              expand="block"
                                            >
                                              Get Now
                                            </IonButton>
                      

                    </IonCardContent>
                  </IonCard>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </section>

        {/* FAQ Section */}
        <section style={{ padding: '2rem',position: 'relative', overflow: 'hidden' }} id="faq">
        <IonIcon
    icon={planet}
    color='primary'
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '500px', // Adjust size as needed
      //color: 'rgb(220,220,220)', // Match the text color
      opacity: '0.2',
      zIndex: '0', // Ensure it stays behind the content
    }}
  />

          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeMd="6">
                <h2>Frequently Asked Questions</h2>
              </IonCol>
              <IonCol size="12" sizeMd="6">
                <IonAccordionGroup>
                  {faqList.map((item, i) => (
                    <IonAccordion value={`faq-${i}`} key={i}>
                      <IonItem slot="header">
                        <IonLabel>{item.question}</IonLabel>
                      </IonItem>
                      <div style={{ background: 'rgb(30,32,44)' }} className="ion-padding" slot="content">
                        {item.answer}
                      </div>
                    </IonAccordion>
                  ))}
                </IonAccordionGroup>
              </IonCol>
            </IonRow>
          </IonGrid>
        </section>

        {/* CTA Section */}
        <section id="form" ref={formRef} style={{  minHeight: '40vh',  padding: '3rem', background: 'rgb(39,45,62', color: '#fff', position: 'relative', overflow: 'hidden',  }}>
          {/* Background Icon */}
          <div style={{ position: 'relative', textAlign: 'center',  }}>
          <h2 style={{ fontSize: '2rem', color:'rgb(220,220,220)'}}>Don't waste too much time making flashcards, to really learn you need comprehensive practice.</h2>
          <p style={{color:'rgb(254,216,111)', fontSize: '1.3rem', opacity:'0.9'}}>Request your Invite Code by filling out the form below.</p>
          </div>
                 {/* Email Submission Form */}
        <form onSubmit={handleSubmitEmail} style={{ padding: '5%' }}>
          {/* Text field for email list*/}
          <div style={{ marginBottom: '20px'}}>
            <IonLabel style={{ display: 'block', fontSize: '18px', marginBottom: '5px' }}>
              How did you hear about us? What will you use it for (learning German, Math, Songs)?
            </IonLabel>
            <IonItem style={{ borderRadius: '20px', border: '1px solid darkgrey', }}>
              <IonInput
                value={howHeard}
                onIonChange={(e) => handleHowHeardChange(e.detail.value!)}
                placeholder="Your answer"
                required
                maxlength={256}
                style={{fontSize:'1.3em'}}
              />
            </IonItem>
          </div>

          {/* Email */}
          <div style={{ marginBottom: '20px'}}>
            <IonLabel style={{ display: 'block', fontSize: '18px', marginBottom: '5px' }}>
              Email
            </IonLabel>
            <IonItem  style={{ borderRadius: '20px', border: '1px solid darkgrey', }}>
              <IonInput
                type="email"
                value={email}
                onIonChange={(e) => handleEmailChange(e.detail.value!)}
                placeholder="Enter your email"
                required
                maxlength={60}
                style={{fontSize:'1.3em'}}
              />
            </IonItem>
          </div>

          <IonButton
            disabled={!isEmailValid}
            type="submit"
            expand="block"
            style={{ marginTop: '20px', paddingLeft:'25px', paddingRight:'25px' }}
            color='success'
          >
            I'm interested! (request code)
          </IonButton>

          {/* New second button to navigate to registration */}
          <IonButton
            expand="block"
            style={{ marginTop: '50px', paddingLeft: '25px', paddingRight: '25px' }}
            color="primary"
            onClick={() => history.push("/signup")}
          >
            Signup if you have an invite code!
          </IonButton>


          {status && (
            <div style={{ marginTop: '20px', padding: '20px', border: '1px solid #ccc', borderRadius: '8px' }}>
              <IonText color={status.includes('successful') ? 'success' : 'danger'}>
                <p style={{ textAlign: 'center' }}>{status}</p>
              </IonText>
              {submittedData && (
                <div style={{ marginTop: '10px' }}>
                  <p><strong>Email:</strong> {submittedData.email}</p>
                  <p><strong>How did you hear about us:</strong> {submittedData.howHeard}</p>
                </div>
              )}
            </div>
          )}
        </form>
          {/* <IonButton onClick={() => history.push("/signup")} color="success" expand="block">Get Space Subs</IonButton> */}
        </section>


        {/* Footer */}
        <IonFooter style={{ padding: '2rem', textAlign: 'center' }}>
          <p>&copy; {new Date().getFullYear()} Space Subs. All rights reserved.</p>
          {/* <a href="#pricing">Pricing</a> | <a href="#faq">FAQ</a> */}
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default LandingPage2;
